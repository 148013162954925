let shoppingListId = 0;
let itemId = 0;

export const INITIAL_SHOPPING = [
  {
    shoppingListId: createShoppingListId(),
    dateCreated: "2023-06-01",
    owner: "John Doe",
    items: [
      {
        itemId: createItemId(),
        quantity: 2,
        unitMeasure: "lb",
        name: "Apples",
        eventIds: [1, 3],
      },
      {
        itemId: createItemId(),
        quantity: 1,
        unitMeasure: "gallon",
        name: "Milk",
        eventIds: [2],
      },
    ],
  },
  {
    shoppingListId: createShoppingListId(),
    dateCreated: "2023-06-05",
    owner: "Jane Smith",
    items: [
      {
        itemId: createItemId(),
        quantity: 1,
        unitMeasure: "dozen",
        name: "Eggs",
        eventIds: [4],
      },
      {
        itemId: createItemId(),
        quantity: 2,
        unitMeasure: "loaf",
        name: "Bread",
        eventIds: [5, 6],
      },
      {
        itemId: createItemId(),
        quantity: 1,
        unitMeasure: "pkg",
        name: "Butter",
        eventIds: [5],
      },
    ],
  },
];

export function createShoppingListId() {
  return String(shoppingListId++);
}

export function createItemId() {
  return String(itemId++);
}
