let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

// Define color variables
export const BREAKFAST_COLOR = "#F6D155";
export const LUNCH_COLOR = "#77C9D4";
export const DINNER_COLOR = "#DDA0DD";

export const BREAKFAST_TIME = "T08:00:00";
export const LUNCH_TIME = "T12:00:00";
export const DINNER_TIME = "T18:00:00";

// Helper function to add days to your date string (todayStr)
export function addDaysToDate(dateStr, days) {
  const date = new Date(dateStr);
  date.setDate(date.getDate() + days);
  return date.toISOString().split("T")[0]; // Returns a string in the format YYYY-MM-DD
}

export const INITIAL_EVENTS = [
  // Day 1
  {
    id: createEventId(),
    title: "Scrambled eggs with spinach and toast",
    start: todayStr + BREAKFAST_TIME,
    backgroundColor: BREAKFAST_COLOR,
    extendedProps: {
      type: "breakfast",
      abbrievation: "eggs & toast",
      icon: "🍳",
    },
  },
  {
    id: createEventId(),
    title: "Turkey and avocado sandwich",
    start: todayStr + LUNCH_TIME,
    backgroundColor: LUNCH_COLOR,
    extendedProps: {
      type: "lunch",
      abbrievation: "turkey & avocado",
      icon: "🥪",
    },
  },
  {
    id: createEventId(),
    title: "Spaghetti Bolognese",
    start: todayStr + DINNER_TIME,
    backgroundColor: DINNER_COLOR,
    extendedProps: {
      type: "dinner",
      abbrievation: "spaghetti",
      icon: "🍝",
    },
  },
  // Day 2
  {
    id: createEventId(),
    title: "Croissant and fruit salad",
    start: addDaysToDate(todayStr, 1) + BREAKFAST_TIME,
    backgroundColor: BREAKFAST_COLOR,
    extendedProps: {
      type: "breakfast",
      abbrievation: "croiss. & fruit",
      icon: "🥐",
    },
  },
  {
    id: createEventId(),
    title: "Greek salad with grilled chicken",
    start: addDaysToDate(todayStr, 1) + LUNCH_TIME,
    backgroundColor: LUNCH_COLOR,
    extendedProps: {
      type: "lunch",
      abbrievation: "greek salad",
      icon: "🥗",
    },
  },
  {
    id: createEventId(),
    title: "Chicken curry with rice",
    start: addDaysToDate(todayStr, 1) + DINNER_TIME,
    backgroundColor: DINNER_COLOR,
    extendedProps: {
      type: "dinner",
      abbrievation: "chicken curry",
      icon: "🍛",
    },
  },
  // Day 3
  {
    id: createEventId(),
    title: "French toast with berries",
    start: addDaysToDate(todayStr, 2) + BREAKFAST_TIME,
    backgroundColor: BREAKFAST_COLOR,
    extendedProps: {
      type: "breakfast",
      abbrievation: "french toast",
      icon: "🍞",
    },
  },
  {
    id: createEventId(),
    title: "Fish tacos with mango salsa",
    start: addDaysToDate(todayStr, 2) + LUNCH_TIME,
    backgroundColor: LUNCH_COLOR,
    extendedProps: {
      type: "lunch",
      abbrievation: "fish tacos",
      icon: "🌮",
    },
  },
  {
    id: createEventId(),
    title: "Homemade pizza night",
    start: addDaysToDate(todayStr, 2) + DINNER_TIME,
    backgroundColor: DINNER_COLOR,
    extendedProps: {
      type: "dinner",
      abbrievation: "pizza",
      icon: "🍕",
    },
  },
  // Day 4
  {
    id: createEventId(),
    title: "Pancakes",
    start: addDaysToDate(todayStr, 3) + BREAKFAST_TIME,
    backgroundColor: BREAKFAST_COLOR,
    extendedProps: {
      type: "breakfast",
      icon: "🥞",
    },
  },
  {
    id: createEventId(),
    title: "Caesar Salad",
    start: addDaysToDate(todayStr, 3) + LUNCH_TIME,
    backgroundColor: LUNCH_COLOR,
    extendedProps: {
      type: "lunch",
      icon: "🥗",
    },
  },
  {
    id: createEventId(),
    title: "Spaghetti Carbonara",
    start: addDaysToDate(todayStr, 3) + DINNER_TIME,
    backgroundColor: DINNER_COLOR,
    extendedProps: {
      type: "dinner",
      abbrievation: "spaghetti",
      icon: "🍝",
    },
  },
  // Day 5
  {
    id: createEventId(),
    title: "Omelette",
    start: addDaysToDate(todayStr, 4) + BREAKFAST_TIME,
    backgroundColor: BREAKFAST_COLOR,
    extendedProps: {
      type: "breakfast",
      abbrievation: "omelette",
      icon: "🍳",
    },
  },
  {
    id: createEventId(),
    title: "Ramen",
    start: addDaysToDate(todayStr, 4) + LUNCH_TIME,
    backgroundColor: LUNCH_COLOR,
    extendedProps: {
      type: "lunch",
      icon: "🍜",
    },
  },
  {
    id: createEventId(),
    title: "Beef Stew",
    start: addDaysToDate(todayStr, 4) + DINNER_TIME,
    backgroundColor: DINNER_COLOR,
    extendedProps: {
      type: "dinner",
      icon: "🍲",
    },
  },
];

export function createEventId() {
  return String(eventGuid++);
}
