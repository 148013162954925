import React, { useState, useEffect, useContext } from "react";

// Full Calendar
// import { formatDate } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

// Import Data
import {
  addDaysToDate,
  INITIAL_EVENTS,
  createEventId,
  BREAKFAST_TIME,
} from "../.././controller/event-utils";

//  Home Page
//
//
function Home() {
  const [state, setState] = useState({
    weekendsVisible: true,
    // currentEvents: [],
    meals: [],
  });

  const handleEventDrop = (info) => {
    const { event, delta } = info;
    const { meals } = state;

    // Convert start time strings to Date objects
    let fromDate = new Date(event.start);
    fromDate.setTime(fromDate.getTime() - delta.milliseconds); // Adjusting by delta
    let toDate = new Date(event.start);

    // Get all events of the same type
    const filteredMeals = meals.filter(
      (meal) => meal.extendedProps.type === event.extendedProps.type
    );

    let i = Math.sign(delta.milliseconds);

    filteredMeals.forEach((meal) => {
      let mealStart = new Date(meal.start);
      if (mealStart >= fromDate && mealStart <= toDate) {
        mealStart.setDate(mealStart.getDate() + i);
        meal.start = mealStart.toISOString();
      }
    });

    // Update the state with the modified meals
    setState((prevState) => ({
      ...prevState,
      // meals: meals.map((meal) =>
      //   filteredMeals.find((filteredMeal) => filteredMeal.id === meal.id)
      //     ? { ...meal }
      //     : meal
      // ),
    }));
  };

  const handleEventClick = (clickInfo) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${clickInfo.event.title}'`
      )
    ) {
      clickInfo.event.remove();
    }
  };

  const renderEventContent = (eventInfo) => {
    const { view } = eventInfo;

    if (view.type === "dayGridMonth") {
      return (
        <div
          className="fc-event-container"
          style={{ backgroundColor: eventInfo.event.backgroundColor }}
        >
          <div className="fc-event-content">
            {eventInfo.event.extendedProps.icon && (
              <span className="fc-event-icon">
                {eventInfo.event.extendedProps.icon}
              </span>
            )}
            <span className="fc-event-title">
              {eventInfo.event.extendedProps.abbrievation ||
                eventInfo.event.title}
            </span>
          </div>
        </div>
      );
    }

    return <span>{eventInfo.event.title}</span>;
  };

  return (
    <div>
      <FullCalendar
        themeSystem="standard"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={state.weekendsVisible}
        initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
        eventContent={renderEventContent}
        eventClick={handleEventClick}
        eventDrop={handleEventDrop}
        eventSources={[{ events: state.meals }]}
      />
    </div>
  );
}

export default Home;
