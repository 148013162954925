import React, { useState } from "react";
import {
  INITIAL_SHOPPING,
  createShoppingListId,
  createItemId,
} from "../../controller/shopping-utils";

const ShoppingPage = () => {
  const [shoppingLists, setShoppingLists] = useState(INITIAL_SHOPPING);
  const [selectedShoppingList, setSelectedShoppingList] = useState(null);

  const handleAddShoppingList = () => {
    const newShoppingList = {
      shoppingListId: createShoppingListId(),
      dateCreated: new Date().toISOString().split("T")[0],
      owner: "",
      items: [],
    };
    setShoppingLists([...shoppingLists, newShoppingList]);
  };

  const handleSelectShoppingList = (shoppingListId) => {
    const selected = shoppingLists.find(
      (list) => list.shoppingListId === shoppingListId
    );
    setSelectedShoppingList(selected);
  };

  const handleAddItem = () => {
    const newItem = {
      itemId: createItemId(),
      quantity: 0,
      unitMeasure: "",
      name: "",
      eventIds: [],
    };
    setSelectedShoppingList((prevList) => ({
      ...prevList,
      items: [...prevList.items, newItem],
    }));
  };

  const handleRemoveItem = (itemId) => {
    setSelectedShoppingList((prevList) => ({
      ...prevList,
      items: prevList.items.filter((item) => item.itemId !== itemId),
    }));
  };

  const handleUpdateItem = (updatedItem) => {
    setSelectedShoppingList((prevList) => ({
      ...prevList,
      items: prevList.items.map((item) =>
        item.itemId === updatedItem.itemId ? updatedItem : item
      ),
    }));
  };

  return (
    <div>
      <div>
        <h2>Shopping Lists</h2>
        <button onClick={handleAddShoppingList}>Add Shopping List</button>
        <ul>
          {shoppingLists.map((list) => (
            <li
              key={list.shoppingListId}
              onClick={() => handleSelectShoppingList(list.shoppingListId)}
            >
              Shopping List #{list.shoppingListId} - {list.owner} -{" "}
              {list.dateCreated}
            </li>
          ))}
        </ul>
      </div>
      <div>
        {selectedShoppingList ? (
          <div>
            <h3>
              Selected Shopping List: #{selectedShoppingList.shoppingListId}
            </h3>
            <p>Date Created: {selectedShoppingList.dateCreated}</p>
            <p>Owner: {selectedShoppingList.owner}</p>
            <h4>Items:</h4>
            <ul>
              {selectedShoppingList.items.map((item) => (
                <li key={item.itemId}>
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) =>
                      handleUpdateItem({
                        ...item,
                        quantity: parseInt(e.target.value),
                      })
                    }
                  />
                  <input
                    type="text"
                    value={item.unitMeasure}
                    onChange={(e) =>
                      handleUpdateItem({ ...item, unitMeasure: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    value={item.name}
                    onChange={(e) =>
                      handleUpdateItem({ ...item, name: e.target.value })
                    }
                  />
                  <button onClick={() => handleRemoveItem(item.itemId)}>
                    Remove
                  </button>
                </li>
              ))}
            </ul>
            <button onClick={handleAddItem}>Add Item</button>
          </div>
        ) : (
          <p>No shopping list selected.</p>
        )}
      </div>
    </div>
  );
};

export default ShoppingPage;
