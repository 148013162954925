import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Grid,
  Button,
} from "@mui/material";
import {
  INITIAL_RECIPES,
  createRecipeId,
} from "../.././controller/recipe-utils";

const RecipePage = () => {
  const [recipes, setRecipes] = useState(INITIAL_RECIPES);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRecipes = recipes.filter((recipe) =>
    recipe.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRecipeClick = (recipe) => {
    setSelectedRecipe(recipe);
  };

  const handleAddRecipe = () => {
    // Logic to add a new recipe
    // You can use a form or a modal to collect recipe details
    // and then update the recipes state
  };

  const handleRemoveRecipe = (recipeId) => {
    // Logic to remove a recipe from the recipes state
    setRecipes(recipes.filter((recipe) => recipe.id !== recipeId));
    setSelectedRecipe(null);
  };

  const handleEditRecipe = (updatedRecipe) => {
    // Logic to edit a recipe in the recipes state
    setRecipes(
      recipes.map((recipe) =>
        recipe.id === updatedRecipe.id ? updatedRecipe : recipe
      )
    );
    setSelectedRecipe(updatedRecipe);
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Recipe Book
        </Typography>
        <TextField
          label="Search recipes"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearch}
          margin="normal"
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <List>
              {filteredRecipes.map((recipe) => (
                <ListItem
                  key={recipe.id}
                  button
                  onClick={() => handleRecipeClick(recipe)}
                >
                  <ListItemText primary={recipe.title} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={8}>
            {selectedRecipe ? (
              <Box>
                <Typography variant="h5" gutterBottom>
                  {selectedRecipe.title}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Author: {selectedRecipe.author}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Description: {selectedRecipe.description}
                </Typography>
                {/* Display other recipe fields */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEditRecipe(selectedRecipe)}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleRemoveRecipe(selectedRecipe.id)}
                >
                  Remove
                </Button>
              </Box>
            ) : (
              <Typography>No recipe selected</Typography>
            )}
          </Grid>
        </Grid>
        <Button variant="contained" color="primary" onClick={handleAddRecipe}>
          Add Recipe
        </Button>
      </Box>
    </Container>
  );
};

export default RecipePage;
