let recipeGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, "");

export const INITIAL_RECIPES = [
  {
    id: createRecipeId(),
    title: "Spaghetti Bolognese",
    author: "John Doe",
    ingredients: [
      "1 lb spaghetti",
      "1 lb ground beef",
      "1 onion, chopped",
      "4 cloves garlic, minced",
      "1 can (28 oz) crushed tomatoes",
      "2 tbsp olive oil",
      "1 tsp salt",
      "1/2 tsp black pepper",
      "1/4 cup fresh basil leaves, chopped",
    ],
    directions: [
      "In a large pot, heat olive oil over medium heat. Add onion and garlic, and sauté until softened.",
      "Add ground beef and cook until browned, breaking it up with a wooden spoon.",
      "Stir in crushed tomatoes, salt, and black pepper. Simmer for 20 minutes.",
      "Meanwhile, cook spaghetti according to package instructions until al dente.",
      "Drain the spaghetti and add it to the sauce. Toss to combine.",
      "Serve hot, garnished with fresh basil leaves.",
    ],
    url: "https://example.com/spaghetti-bolognese",
    image: "https://example.com/images/spaghetti-bolognese.jpg",
    icon: "🍝",
    description:
      "A classic Italian pasta dish with a rich tomato and meat sauce.",
    abbreviation: "Spag Bol",
    notes: [
      {
        id: createRecipeId(),
        content: "This recipe is perfect for a cozy dinner at home.",
        timedate: "2023-06-01T18:30:00",
      },
      {
        id: createRecipeId(),
        content: "Leftovers can be stored in the fridge for up to 3 days.",
        timedate: "2023-06-02T10:15:00",
      },
    ],
  },
  {
    id: createRecipeId(),
    title: "Chocolate Chip Cookies",
    author: "Jane Smith",
    ingredients: [
      "2 1/4 cups all-purpose flour",
      "1 tsp baking soda",
      "1 tsp salt",
      "1 cup unsalted butter, softened",
      "3/4 cup white sugar",
      "3/4 cup brown sugar",
      "1 tsp vanilla extract",
      "2 large eggs",
      "2 cups semisweet chocolate chips",
    ],
    directions: [
      "Preheat the oven to 375°F (190°C) and line a baking sheet with parchment paper.",
      "In a medium bowl, whisk together flour, baking soda, and salt.",
      "In a large bowl, beat butter and both sugars until light and fluffy.",
      "Beat in vanilla and eggs, one at a time, until well combined.",
      "Gradually stir in the flour mixture until just combined.",
      "Fold in the chocolate chips.",
      "Drop rounded tablespoons of dough onto the prepared baking sheet.",
      "Bake for 9-11 minutes or until edges are lightly browned.",
      "Cool on the baking sheet for 5 minutes before transferring to a wire rack.",
    ],
    url: "https://example.com/chocolate-chip-cookies",
    image: "https://example.com/images/chocolate-chip-cookies.jpg",
    icon: "🍪",
    description:
      "Soft and chewy chocolate chip cookies that are perfect for any occasion.",
    abbreviation: "Choc Chip Cookies",
    notes: [
      {
        id: createRecipeId(),
        content: "These cookies are best enjoyed with a cold glass of milk.",
        timedate: "2023-06-03T15:00:00",
      },
    ],
  },
  {
    id: createRecipeId(),
    title: "Hearty Vegetable Soup",
    author: "Chef Ramsey",
    ingredients: [
      "2 tbsp olive oil",
      "1 onion, chopped",
      "2 carrots, diced",
      "3 garlic cloves, minced",
      "1 cup of green beans",
      "4 cups vegetable broth",
      "2 cups water",
      "1 can diced tomatoes",
      "1 can kidney beans, rinsed and drained",
      "1 tsp dried oregano",
      "Salt and pepper to taste",
    ],
    directions: [
      "Heat olive oil in a large pot over medium heat.",
      "Add the onion and carrots, cook until softened, about 5 minutes.",
      "Stir in garlic and cook for 1 more minute.",
      "Add green beans, vegetable broth, water, tomatoes, kidney beans, and oregano.",
      "Bring to a boil, then reduce heat and simmer for 20 minutes.",
      "Season with salt and pepper.",
      "Serve hot.",
    ],
    url: "https://www.fakerecipesite.com/hearty-vegetable-soup",
    image: "https://www.fakeimagehost.com/images/hearty-vegetable-soup.jpg",
    icon: "🍲", // Unicode soup pot emoji
    description: "A warm and comforting vegetable soup perfect for cold days.",
    abbreviation: "Veg Soup",
    notes: [
      {
        text: "Can add pasta or rice for a more filling meal.",
        timedate: "2024-03-20T09:00:00",
      },
      {
        text: "Try adding spinach or kale during the last 5 minutes of cooking.",
        timedate: "2024-03-21T10:00:00",
      },
    ],
  },
  // ... more recipes
];

export function createRecipeId() {
  return String(recipeGuid++);
}
