import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

function onlyDays({ date }) {
  var weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  return weekdays[date.getDay()];
}

function Test() {
  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "",
          center: "",
          right: "",
        }}
        titleFormat={{
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          weekday: "short",
        }}
        dayHeaderContent={onlyDays}
        initialView="dayGridWeek"
      />
    </div>
  );
}

export default Test;
