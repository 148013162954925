import React, { useState, useEffect } from "react";
import { INITIAL_RECIPES, createRecipeId } from "../../controller/recipe-utils";

const CookingPage = () => {
  const [selectedRecipe, setSelectedRecipe] = useState(() => {
    const recipe = INITIAL_RECIPES[0];
    return {
      ...recipe,
      ingredients: recipe.ingredients.map((ingredient) => ({
        name: ingredient, // assuming ingredient is initially just a string
        checked: false, // initializing all ingredients as unchecked
      })),
      directions: recipe.directions.map((direction) => ({
        step: direction, // assuming direction is initially just a string
        checked: false, // initializing all directions as unchecked
      })),
    };
  });
  const [notes, setNotes] = useState(selectedRecipe.notes || []);
  const [newNote, setNewNote] = useState("");
  const [notesExpanded, setNotesExpanded] = useState(true);
  const [notesHeight, setNotesHeight] = useState(200);

  useEffect(() => {
    // Update the ingredients to objects when selectedRecipe changes
    setSelectedRecipe((prevRecipe) => ({
      ...prevRecipe,
      ingredients: prevRecipe.ingredients.map((ingredient) =>
        typeof ingredient === "string"
          ? { name: ingredient, checked: false }
          : ingredient
      ),
    }));
  }, [selectedRecipe.title]); // Assuming title is unique for recipes

  const handleIngredientCheck = (index) => {
    setSelectedRecipe((prevRecipe) => {
      const updatedIngredients = prevRecipe.ingredients.map((ingredient, i) => {
        if (i === index) {
          // Flip the checked status of the targeted ingredient
          return { ...ingredient, checked: !ingredient.checked };
        }
        return ingredient;
      });
      return { ...prevRecipe, ingredients: updatedIngredients };
    });
  };

  const handleDirectionCheck = (index) => {
    setSelectedRecipe((prevRecipe) => {
      const updatedDirections = [...prevRecipe.directions];
      updatedDirections[index].checked = !updatedDirections[index].checked;
      return { ...prevRecipe, directions: updatedDirections };
    });
  };

  const handleNoteChange = (e) => {
    setNewNote(e.target.value);
  };

  const handleAddNote = () => {
    if (newNote.trim() !== "") {
      const newNoteObj = {
        id: createRecipeId(),
        content: newNote.trim(),
        timedate: new Date().toLocaleString(),
      };
      setNotes((prevNotes) => [...prevNotes, newNoteObj]);
      setNewNote("");
    }
  };

  const handleNotesResize = (e) => {
    const startY = e.clientY;
    const startHeight = notesHeight;

    const handleMouseMove = (e) => {
      const newHeight = startHeight + (startY - e.clientY);
      setNotesHeight(newHeight);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <div>
      <h2>{selectedRecipe.title}</h2>
      <h3>Ingredients:</h3>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <ul
          style={{
            display: "flex",
            flexWrap: "wrap",
            listStyle: "none",
            padding: 0,
          }}
        >
          {selectedRecipe.ingredients.map((ingredient, index) => (
            <li key={index} style={{ width: "50%", flex: "0 1 auto" }}>
              <label>
                <input
                  type="checkbox"
                  checked={ingredient.checked || false}
                  onChange={() => handleIngredientCheck(index)}
                />
                {ingredient.name}
              </label>
            </li>
          ))}
        </ul>
      </div>
      <hr />
      <div>
        <h3>Directions:</h3>
        <ol>
          {selectedRecipe.directions.map((direction, index) => (
            <li key={index}>
              <label>
                <input
                  type="checkbox"
                  checked={direction.checked}
                  onChange={() => handleDirectionCheck(index)}
                />
                {direction.step}
              </label>
            </li>
          ))}
        </ol>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          width: "300px",
          height: notesExpanded ? `${notesHeight}px` : "40px",
          backgroundColor: "#f5f5f5",
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "10px",
          overflow: "auto",
          transition: "height 0.3s ease",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setNotesExpanded(!notesExpanded)}
        >
          <h3 style={{ margin: 0 }}>Notes</h3>
          <div style={{ cursor: "ns-resize" }} onMouseDown={handleNotesResize}>
            &#9776;
          </div>
        </div>
        {notesExpanded && (
          <>
            <div>
              {notes.map((note) => (
                <div key={note.id}>
                  <p>
                    <small>{note.timedate}</small>
                    <br />
                    {note.content}
                  </p>
                </div>
              ))}
            </div>
            <div>
              <textarea value={newNote} onChange={handleNoteChange} />
              <button onClick={handleAddNote}>Add Note</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CookingPage;
