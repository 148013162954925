import React from "react";

import myIcon from "./logo-firecake192.png";

const FireLogoIcon = () => {
  return (
    <img
      src={myIcon}
      alt="My Icon"
      style={{
        width: "3.0rem",
        height: "3.0rem",
      }}
    />
  );
};

// const FireLogoIcon = (props) => (
//   <SvgIcon {...props}>
//     {/* Paste the SVG code here */}
//     {/* Make sure to remove the XML declaration and DOCTYPE lines */}
//     {/* Adjust the width, height, and other attributes as needed */}
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.000000 192.000000">
//       <g
//         transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)"
//         fill="#ffffff"
//         stroke="none"
//       >
//         <path
//           d="M207 1810 c-8 -11 -16 -17 -19 -14 -8 8 -84 -84 -114 -139 -25 -47
// -27 -66 -4 -52 6 4 9 13 6 21 -3 8 -2 14 3 14 9 0 91 118 91 132 0 5 4 7 9 3
// 5 -3 16 4 24 15 7 11 16 18 19 15 2 -3 5 2 5 10 0 20 -3 19 -20 -5z"
//         />
//         <path
//           d="M1707 1814 c-2 -3 17 -28 44 -56 61 -64 104 -139 127 -225 14 -50 20
// -61 24 -45 4 12 2 25 -3 28 -6 3 -7 14 -4 25 4 10 2 19 -4 19 -6 0 -8 6 -5 14
// 3 7 -2 20 -10 29 -8 8 -12 17 -9 21 4 3 1 6 -6 6 -7 0 -9 5 -6 10 3 6 1 10 -5
// 10 -6 0 -8 4 -5 10 3 5 -5 21 -19 35 -14 14 -26 28 -26 30 -1 10 -88 94 -93
// 89z"
//         />
//         <path
//           d="M1204 1784 c50 -47 70 -127 45 -177 -6 -12 -48 -60 -93 -106 -44 -46
// -71 -78 -59 -72 13 6 33 21 46 34 12 12 38 36 58 52 51 43 73 82 79 139 5 50
// 6 51 20 26 10 -17 15 -52 15 -110 -1 -156 -77 -299 -211 -394 -39 -28 -95 -68
// -125 -90 -30 -21 -46 -36 -36 -32 18 6 25 -18 7 -29 -5 -3 -10 -14 -10 -25 0
// -17 -27 -36 -38 -25 -3 3 -1 26 5 51 7 30 7 42 0 35 -5 -5 -12 -33 -15 -60 -5
// -56 -5 -55 -30 -42 -9 5 -25 12 -34 15 -24 7 -33 66 -15 107 8 19 44 61 81 94
// 64 56 104 110 92 123 -3 3 -6 -1 -6 -8 0 -8 -40 -53 -89 -101 -98 -95 -114
// -129 -93 -195 5 -19 9 -34 8 -34 -2 0 -17 5 -34 11 -71 25 -40 128 71 236 35
// 34 71 77 80 96 22 45 22 158 1 242 -16 64 -13 128 6 140 6 4 7 -6 4 -24 -6
// -26 22 -138 32 -129 2 2 -4 27 -13 56 -15 47 -15 55 -1 84 l15 33 1 -65 c0
// -57 6 -76 47 -155 44 -83 47 -94 42 -146 -3 -31 -14 -78 -26 -104 -16 -36 -17
// -44 -5 -34 41 34 62 126 45 199 -10 41 -9 47 11 63 90 76 94 80 73 91 -39 22
// -85 74 -85 98 -1 22 -1 22 -15 4 -20 -26 -19 -56 4 -113 14 -36 16 -54 9 -73
// -8 -18 -12 -21 -15 -10 -3 8 -19 43 -34 77 -19 41 -29 79 -29 109 0 43 4 51
// 45 91 25 24 40 43 33 43 -32 0 -90 -23 -118 -47 -64 -53 -74 -112 -40 -221 11
// -35 20 -83 20 -106 0 -60 -41 -135 -101 -186 -28 -24 -64 -61 -80 -83 -25 -34
// -29 -49 -29 -105 l0 -64 -25 16 c-13 9 -36 16 -50 16 -47 0 -61 91 -24 167 25
// 52 55 78 151 131 88 48 117 77 125 125 5 32 1 39 -35 74 -45 43 -60 83 -43
// 119 6 13 7 24 1 24 -22 0 -59 -24 -68 -44 -8 -17 -5 -25 14 -39 29 -21 60 -79
// 69 -128 7 -39 -7 -85 -27 -86 -7 0 -21 -4 -31 -8 -14 -6 -16 -4 -10 11 4 11
// -20 -9 -52 -44 -47 -51 -60 -60 -67 -48 -6 11 -2 25 13 46 13 16 26 30 30 30
// 17 0 61 92 63 130 1 35 3 38 14 23 15 -20 15 -13 2 22 -6 16 -14 23 -22 19 -6
// -4 -8 -3 -5 4 11 17 -3 42 -34 57 -43 23 -44 18 -5 -25 31 -34 36 -47 36 -89
// 0 -53 -25 -95 -94 -160 -23 -23 -32 -37 -25 -42 7 -4 9 -15 5 -25 -7 -18 -8
// -18 -20 -2 -12 16 -14 16 -24 -8 -16 -33 -15 -54 1 -34 11 14 37 25 37 16 0
// -2 -12 -24 -26 -50 -20 -36 -25 -55 -20 -81 7 -36 -9 -58 -25 -34 -14 22 -10
// 136 5 174 8 19 47 68 85 109 39 41 71 82 71 91 0 9 -11 38 -25 66 -26 51 -66
// 83 -118 95 -35 7 -60 -11 -28 -21 26 -8 51 -54 51 -92 0 -20 3 -24 11 -16 8 8
// 7 24 -4 58 -11 31 -13 45 -5 43 24 -8 50 -57 55 -100 6 -53 -4 -74 -82 -183
// -30 -42 -55 -83 -55 -91 0 -24 -18 -15 -27 14 -15 43 4 102 52 167 25 33 45
// 64 45 68 0 18 -63 67 -97 76 -48 13 -85 5 -125 -30 l-33 -28 52 6 c48 5 54 3
// 82 -24 25 -25 31 -28 36 -14 4 11 -4 25 -25 43 l-32 26 37 -8 c21 -5 47 -18
// 58 -29 17 -17 18 -22 7 -36 -9 -11 -16 -13 -24 -5 -7 7 -17 -9 -32 -52 -38
// -110 -48 -132 -77 -157 -60 -51 -60 -52 -58 -439 1 -194 6 -364 11 -376 34
// -91 218 -171 450 -197 52 -6 210 -26 350 -45 140 -18 280 -36 310 -39 51 -4
// 52 -4 17 5 -22 6 -80 17 -130 25 -51 8 -99 16 -107 18 -8 2 -48 7 -88 11 -40
// 5 -71 10 -69 12 2 2 57 11 122 20 163 21 298 50 348 74 23 11 33 17 22 14 -97
// -30 -284 -68 -293 -59 -2 2 14 9 37 15 62 16 163 65 195 94 22 19 34 45 47 94
// 17 69 28 80 72 68 27 -7 43 -32 53 -80 6 -35 3 -40 -40 -81 -43 -42 -44 -45
// -20 -43 14 2 41 17 60 36 38 37 43 69 19 116 -28 54 -25 56 90 56 98 0 105 1
// 105 20 0 23 -8 26 -24 6 -9 -11 -31 -12 -119 -6 -60 5 -126 11 -147 15 l-39 7
// -3 297 c-3 278 -4 299 -23 326 -15 22 -20 47 -21 95 0 48 -8 82 -28 130 -33
// 76 -30 119 10 162 26 26 26 28 7 28 -28 0 -67 -33 -87 -74 -18 -39 -24 -202
// -6 -191 6 3 10 39 11 78 l0 72 19 -55 c26 -76 25 -135 -4 -196 -36 -77 -93
// -119 -209 -155 -81 -26 -107 -39 -143 -73 l-43 -42 -35 17 c-34 16 -36 16 -42
// -3 -3 -11 -3 -22 0 -25 3 -3 66 -5 141 -4 172 2 246 15 333 60 23 12 42 23 42
// 25 0 2 -53 -12 -117 -30 -64 -19 -139 -36 -167 -39 -48 -6 -46 -4 34 23 142
// 48 202 86 239 152 32 57 9 49 -41 -14 -24 -30 -49 -55 -55 -55 -7 -1 -13 -4
// -13 -9 0 -9 -62 -36 -119 -52 -55 -15 -141 -21 -141 -10 0 14 62 46 115 60
// 153 40 255 142 255 256 0 57 12 32 16 -33 4 -72 6 -80 28 -122 31 -58 5 -206
// -46 -261 -18 -19 -27 -21 -78 -15 -54 6 -59 5 -93 -25 -20 -17 -39 -40 -42
// -51 -9 -28 -62 -52 -97 -45 -17 3 -44 18 -61 32 -41 35 -104 64 -140 64 -42 0
// -95 -26 -143 -70 -72 -66 -120 -54 -187 44 -27 39 -60 49 -102 32 -27 -12 -36
// -23 -45 -55 -13 -50 -38 -66 -68 -45 -20 14 -22 24 -22 105 0 65 -5 99 -17
// 122 -10 19 -17 55 -17 89 -1 49 4 62 32 97 17 22 32 45 32 50 0 25 28 8 34
// -20 10 -53 6 -66 -11 -34 -17 32 -29 21 -19 -18 8 -32 84 -105 138 -133 71
// -36 193 -66 295 -71 l91 -6 0 37 c0 79 78 164 172 186 25 5 74 17 110 25 57
// 13 71 21 113 66 l47 51 -25 30 c-25 29 -25 33 -23 166 3 127 1 140 -21 182
// -30 58 -67 97 -113 121 -51 26 -58 24 -24 -7z m122 -401 c3 -10 13 -35 22 -56
// 11 -28 13 -45 6 -65 -5 -15 -12 -25 -16 -22 -3 3 -15 -4 -27 -14 -12 -11 -21
// -16 -21 -11 0 4 -6 3 -12 -2 -13 -9 -88 -18 -88 -10 0 2 9 15 20 28 11 13 23
// 29 28 34 4 6 21 38 37 73 31 65 42 74 51 45z m219 -970 c-16 -91 -205 -170
// -467 -194 -264 -26 -584 47 -673 153 -25 29 -25 32 -25 211 1 140 3 177 12
// 162 17 -30 69 -75 88 -75 18 0 40 19 40 34 0 6 -5 5 -12 -2 -7 -7 -23 -11 -38
// -11 -20 1 -21 2 -5 6 30 7 65 44 65 68 0 31 33 58 60 50 12 -4 37 -28 55 -54
// 39 -54 104 -91 159 -91 31 0 40 6 66 43 47 64 105 83 164 52 16 -9 31 -13 34
// -10 8 8 -64 35 -93 35 -37 0 -78 -24 -111 -65 -22 -27 -39 -37 -69 -41 l-40
// -5 40 19 c22 11 51 32 65 47 34 37 72 55 117 55 40 0 108 -34 99 -49 -6 -9 68
// -82 82 -80 4 0 16 4 27 9 18 8 17 9 -7 9 -15 1 -40 12 -55 26 l-28 25 30 -15
// c17 -8 50 -15 74 -15 44 0 61 12 128 94 20 25 28 27 80 24 32 -1 68 1 81 5 14
// 5 22 4 22 -2 0 -11 -9 -20 -55 -57 -11 -8 -1 -5 23 7 34 19 42 28 42 51 0 16
// 6 39 13 51 10 18 13 -18 15 -208 2 -126 0 -244 -3 -262z"
//         />
//         <path
//           d="M1510 732 c0 -32 -5 -63 -12 -70 -9 -9 -9 -12 0 -12 7 0 12 -24 15
// -62 2 -35 4 -3 5 70 1 72 0 132 -3 132 -3 0 -5 -26 -5 -58z"
//         />
//         <path
//           d="M585 720 c22 -9 54 -20 70 -24 22 -4 17 1 -20 19 -27 13 -59 24 -70
// 24 -13 -1 -6 -7 20 -19z"
//         />
//         <path
//           d="M970 694 c0 -25 -2 -26 -42 -20 -24 3 -50 2 -58 -4 -11 -7 4 -10 51
// -10 l66 0 -5 30 c-5 37 -12 39 -12 4z"
//         />
//         <path
//           d="M970 500 c0 -160 6 -189 15 -79 4 35 8 70 10 77 3 6 1 12 -4 12 -4 0
// -8 30 -9 66 0 36 -3 68 -6 72 -3 3 -6 -64 -6 -148z"
//         />
//         <path
//           d="M1222 478 c3 -24 35 -35 64 -24 27 10 -2 46 -37 46 -26 0 -30 -3 -27
// -22z m58 -3 c0 -16 -35 -21 -44 -6 -8 12 4 21 26 21 10 0 18 -7 18 -15z"
//         />
//         <path
//           d="M404 464 c3 -9 6 -20 6 -25 0 -6 4 -8 9 -5 4 3 20 0 34 -7 20 -9 25
// -17 21 -32 -3 -13 -2 -16 3 -8 7 10 20 9 59 -5 46 -17 76 -24 212 -51 65 -13
// 217 -14 187 -1 -11 5 -47 9 -80 10 -135 2 -301 46 -400 105 -37 23 -55 29 -51
// 19z"
//         />
//         <path
//           d="M626 472 c-9 -14 0 -18 58 -27 38 -5 47 -4 44 7 -3 8 -20 13 -44 13
// -21 0 -42 3 -45 8 -4 5 -9 5 -13 -1z"
//         />
//         <path
//           d="M1505 460 c-9 -14 -79 -49 -145 -72 -78 -27 -205 -47 -305 -49 l-85
// -1 2 -52 c1 -50 2 -50 5 -8 3 31 8 42 21 42 164 3 445 64 494 107 14 13 17 12
// 21 -4 3 -10 5 -4 6 15 1 33 -3 40 -14 22z"
//         />
//         <path
//           d="M574 1742 c-30 -20 -18 -72 17 -72 27 0 47 44 29 65 -14 16 -29 19
// -46 7z"
//         />
//         <path
//           d="M712 1717 c-12 -13 -22 -34 -22 -47 l0 -23 23 21 c24 23 71 30 82 12
// 10 -17 25 -11 25 9 0 50 -70 68 -108 28z"
//         />
//         <path
//           d="M1506 1718 c-27 -39 18 -80 52 -46 16 16 15 33 -4 52 -21 21 -30 20
// -48 -6z"
//         />
//         <path
//           d="M337 1703 c-12 -11 -7 -50 7 -62 19 -16 56 2 56 27 0 32 -43 56 -63
// 35z"
//         />
//         <path
//           d="M1114 1685 c-4 -9 -2 -21 4 -27 15 -15 44 -1 40 19 -4 23 -36 29 -44
// 8z"
//         />
//         <path
//           d="M1422 1623 c2 -10 10 -18 18 -18 8 0 16 8 18 18 2 12 -3 17 -18 17
// -15 0 -20 -5 -18 -17z"
//         />
//         <path
//           d="M422 1613 c2 -10 10 -18 18 -18 22 0 18 29 -3 33 -14 3 -18 -1 -15
// -15z"
//         />
//         <path
//           d="M257 1573 c-15 -14 -7 -43 12 -43 26 0 36 17 21 35 -14 16 -23 19
// -33 8z"
//         />
//         <path
//           d="M1650 1561 c0 -26 17 -36 35 -21 18 15 8 40 -16 40 -12 0 -19 -7 -19
// -19z"
//         />
//         <path
//           d="M1572 1488 c-16 -16 -15 -43 3 -58 13 -10 19 -10 35 5 24 22 25 29 4
// 49 -19 19 -26 20 -42 4z"
//         />
//         <path
//           d="M555 1358 c-48 -66 -50 -77 -6 -29 23 24 41 50 41 57 0 20 -1 18 -35
// -28z"
//         />
//         <path
//           d="M392 1368 c2 -12 10 -23 18 -23 22 0 19 39 -3 43 -15 3 -18 -1 -15
// -20z"
//         />
//         <path
//           d="M254 1355 c-9 -23 3 -45 25 -45 10 0 21 7 25 15 8 23 -4 45 -25 45
// -11 0 -22 -7 -25 -15z"
//         />
//         <path
//           d="M1610 1354 c-11 -12 -10 -18 3 -32 15 -15 19 -15 32 -1 17 17 11 49
// -10 49 -7 0 -18 -7 -25 -16z"
//         />
//         <path
//           d="M677 1202 c-48 -49 -48 -60 -1 -15 20 19 43 32 50 29 8 -3 11 0 7 10
// -8 21 -15 18 -56 -24z"
//         />
//         <path
//           d="M1187 1135 c-88 -19 -186 -75 -132 -75 18 0 20 -26 3 -36 -6 -4 -17
// 1 -24 10 -12 17 -13 16 -24 -4 -6 -11 -9 -26 -6 -33 3 -9 5 -9 5 1 1 6 12 12
// 25 12 33 0 61 28 46 45 -16 20 31 52 98 66 31 7 64 16 72 20 20 11 7 10 -63
// -6z"
//         />
//         <path
//           d="M1440 955 c-43 -25 -172 -64 -258 -79 -106 -18 -118 -29 -25 -22 82
// 6 267 65 298 96 23 23 19 25 -15 5z"
//         />
//         <path
//           d="M11 499 c-1 -104 13 -171 54 -254 39 -81 109 -159 177 -199 44 -26
// 108 -50 108 -40 0 2 -26 21 -58 41 -80 51 -112 79 -150 132 -73 100 -94 160
// -121 341 l-9 55 -1 -76z"
//         />
//         <path
//           d="M1886 443 c-3 -3 -6 -30 -6 -59 0 -68 -48 -144 -90 -144 -14 0 -30 4
// -35 9 -6 4 -18 0 -29 -12 -19 -20 -18 -20 16 -13 19 3 37 3 41 0 17 -17 -81
// -144 -111 -144 -7 0 -11 -4 -8 -9 3 -5 -14 -21 -39 -35 -53 -31 -56 -38 -14
// -26 130 38 281 228 296 373 5 50 -4 77 -21 60z"
//         />
//       </g>
//     </svg>
//   </SvgIcon>
// );

export default FireLogoIcon;
