import React from "react";

// Material UI
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

// Import App Parts
import FireNavBar from "./app-parts/navBar";
import FireSideBar, { DrawerHeader } from "./app-parts/sideBar";
import FireRouter from "./app-parts/routes";

// CSS
import "./App.css";

//  Main App
//
export default function App() {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* Nav Bar Top */}
      <FireNavBar open={open} handleDrawerOpen={handleDrawerOpen} />
      {/* Side Bar */}
      <FireSideBar open={open} handleDrawerClose={handleDrawerClose} />
      {/* Main Page */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {/* <ContextProviders> */}
        <FireRouter />
        {/* </ContextProviders> */}
      </Box>
    </Box>
  );
}
