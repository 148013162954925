import React from "react";

// React Router
import { Routes, Route } from "react-router-dom";

// Import Pages
import Home from "../pages/Home/home";
import Recipe from "../pages/Recipe/recipe";
import Shopping from "../pages/Shopping/shopping";
import Cooking from "../pages/Cooking/cooking";
import Test from "../pages/Test/test";
import NotFound from "../pages/notFound/notFound";

function FireRouter() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/recipes" element={<Recipe />} />
      <Route path="/shopping" element={<Shopping />} />
      <Route path="/cooking" element={<Cooking />} />
      <Route path="/test" element={<Test />} />
      <Route element={<NotFound />} />
    </Routes>
  );
}

export default FireRouter;
